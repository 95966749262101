<!-- MainBottom -->
<template>
  <div class='container'>
    <div class="content">
      <div class="bottom_logo">
        <div>
          <div class="top">
            <p>{{ bottom.left_title1 }}</p>
            <img :src="bottom.left_logo1" alt="">
          </div>
          <div class="bottom">
            <p>{{ bottom.left_title2 }}</p>
            <img :src="bottom.left_logo2" alt="">
          </div>
        </div>
      </div>
      <div class="bottom_enquiry">
        <div>
          <div class="top">
            <p>{{ bottom.url_title1 }}</p>
            <span @click="goToURL(bottom.ur_content_url1)">{{ bottom.ur_content1 }}</span>
          </div>
          <div class="bottom">
            <h4>{{ bottom.url_title2 }}</h4>
            <span @click="goToURL(bottom.ur_content_url2)">{{ bottom.ur_content2 }}</span>
          </div>
        </div>
      </div>
      <div class="bottom_Waitro">
        <div style="width: 80%; max-width: 100%;">
          <p>{{ bottom.url_title3 }}</p>
          <div>
            <span @click="goToURL(bottom.ur_content_url31)">{{ bottom.ur_content31 }}</span>
          </div>
          <div>
            <span @click="goToURL(bottom.ur_content_url32)">{{ bottom.ur_content32 }}</span>
          </div>
          <span @click="goToURL(bottom.ur_content_url33)">{{ bottom.ur_content33 }}</span>
        </div>
      </div>
      <div class="bottom_Legal">
        <div style="width: 80%;">
          <p>Agreement</p>
          <div @click="selectFileType(userOssId)">
            <span>User Agreement</span>
          </div >
          <div @click="selectFileType(registerOssId)">
            <span>Registration Agreement</span>
          </div >
        </div>
      </div>
    </div>
    <!-- <div class="lfti">
      <div class="imgs">
        <img src="../../assets/bottomIcons/linkedin.png" :width="50" alt="">
        <img src="../../assets/bottomIcons/facebook.png" alt="">
        <img src="../../assets/bottomIcons/twitter.png" alt="">
        <img src="../../assets/bottomIcons/instagram.png" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
import { getApiFooter } from '@/api/home';
import {getApiProtocol} from "@/api/order";
export default {
  name: 'MainBottom',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      bottom: {},
      files: [],
      userOssId: 0,
      registerOssId: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getList() {
      getApiFooter().then(response => {
        this.bottom = response.data;
      })
      getApiProtocol().then(response => {
        this.userOssId = response.data.type1.protocol_url_id;
        this.registerOssId = response.data.type2.protocol_url_id;
      })
    },
    goToURL(url) {
      window.open(url, '_blank');
    },
    selectFileType(ossId) {
      console.log('this.ossId:', ossId);
      if (this.$download && this.$download.oss) {
        this.$download.oss(ossId);
      } else {
        console.error('Download service is not available.');
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类

.container {
  background-color: #474C55;
  padding: 0;
}

.content {
  background-color: #474C55;
  height: 230px;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom_logo {
  width: 25%;
  line-height: 30px;
  color: white;
  display: flex;
  justify-content: center;
}
.bottom_logo img {
  width: 280px;
  height: 45px;
}
.bottom_enquiry {
  width: 25%;
  line-height: 40px;
  color: white;
  display: flex;
  justify-content: center;
}

.bottom_Waitro {
  width: 25%;
  line-height: 40px;
  color: white;
  display: flex;
  justify-content: center;
}

.bottom_Legal {
  width: 25%;
  line-height: 60px;
  color: white;
  display: flex;
  justify-content: center;
}

span {
  color: #05B8B0;
  text-decoration: underline;
  cursor: pointer;
  /* 光标变成小手 */
}

.lfti {
  background-color: #05B8B0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgs {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>