import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
// import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import cache from '@/plugins/cache'
import { tansParams, blobValidate } from "@/utils/ruoyi";
import errorCode from '@/utils/errorCode'
import { saveAs } from 'file-saver'

let downloadLoadingInstance;
export let isRelogin = { show: false };


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
axios.defaults.headers['Content-Language'] = 'zh_CN'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',
  // baseURL: 'https://interface.waitrosummit2024.org',
  // 超时
  timeout: 30000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = 'Data is being processed, please do not resubmit';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  // const msg = errorCode[code] || res.data.msg || errorCode['default'];
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data;
  }
  if (code === 401) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      MessageBox.confirm('The login status has expired. You can continue to stay on this page or log in again',
        'System prompt', {
        confirmButtonText: 'Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        isRelogin.show = false;
        removeToken();
        location.href = '/Register-and-login'
      }).catch(() => {
        isRelogin.show = false;
      });
    }
    // 不要在这里拒绝Promise，而是返回完整的响应对象
    return res;
  } else if (code === 500 || code === 601 || code !== 200) {
    // Message({ message: "Network Error", type: code === 601 ? 'warning' : 'error' });
    // 返回完整的响应对象
    return res;
  } else if (code === 99){
    return res;
  }else {
    return res.data;
  }
},
  error => {
    console.log('err' + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "Abnormal backend interface connection";
    } else if (message.includes("timeout")) {
      message = "System interface request timeout";
    } else if (message.includes("Request failed with status code")) {
      message = "System interface " + message.substr(message.length - 3) + "exceptional";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 });
    return Promise.reject(error);
  }
);


// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "Downloading data, please wait", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('There was an error downloading the file. Please contact the administrator!')
    downloadLoadingInstance.close();
  })
}

export default service
