import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import './assets/styles.css'; // 引入公共样式
import 'element-ui/lib/theme-chalk/base.css';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import download from '@/plugins/download';


import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'


Vue.config.productionTip = false
Vue.prototype.$download = download;

Vue.use(ElementUI, { locale } ,VideoPlayer);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
