import request from '@/utils/request'

// 登录方法
export function login(email_address, password) {
  const data = {
    email_address,
    password,
  }
  return request({
    url: '/index/task/api_login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 邮箱登录方法
export function codeLogin(email_address, verificationCode) {
  const data = {
    email_address,
    verificationCode,
  }
  return request({
    url: '/index/task/api_login_verification_code',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/index/task/api_register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data

  })
}
// 忘记密码
export function forgetPassword(data) {
  return request({
    url: '/index/task/api_forget',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data

  })
}
// 修改密码
export function forgetPasswordSku(data) {
  return request({
    url: '/index/task/api_forget_sku',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data

  })
}
// 修改个人信息
export function updateUserInfo(data) {
  return request({
    url: '/index/task/api_user_information_update',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data

  })
}


// 获取用户详细信息
export function getInfo(sku) {
  const data = {
    sku
  }
  return request({
    url: '/index/task/api_user_information',
    method: 'get',
    params: data
  })
}


// 获取用户订单信息
export function apiUserOrder(sku) {
  const data = {
    sku
  }
  return request({
    url: '/index/task/api_user_order',
    method: 'get',
    params: data
  })
}
// 获取用户选择酒店信息
export function getHotelRoomList(sku) {
  return request({
    url: '/index/task/api_user_order_hotel_new?sku=' + sku,
    method: 'get',
  })
}
// 获取用户订单信息
export function apiUserOrderStatus(sku) {
  const data = {
    sku
  }
  return request({
    url: '/index/task/api_user_order_status',
    method: 'get',
    params: data
  })
}

// 退款原因
export function apiUserOrderRefund(order_refund, order_id, sku) {
  const data = {
    order_refund,
    order_id,
    sku,
  }
  return request({
    url: '/index/task/api_user_order_refund',
    method: 'get',
    params: data
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 短信验证码
export function getCodeSms() {
  return request({
    url: '/captchaSms',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 注册下拉框
export const getUserEnumeration = () => {
  return request({
    url: '/index/task/api_user_enumeration',
    method: 'get',
  })
}

// 注册下拉框
export const getRegisterInformation = () => {
  return request({
    url: '/index/task/api_register_information',
    method: 'get',
  })
}
