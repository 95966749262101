import Vue from 'vue';
import Router from 'vue-router';
// import { MessageBox } from 'element-ui';


Vue.use(Router);
// 解决报错 此方法只针对VueRouter3.0以上
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
    mode: 'history', //去掉url中的#
    routes: [
        {path: '/', redirect: '/home-view'},
        {path: '/home-view', name: 'HomeView', component: () => import('@/views/HomeView.vue')},
        {
            path: '/about-sponsor',
            name: 'ParticipatingEnterprises',
            component: () => import('@/views/ParticipatingEnterprises.vue')
        },
        {path: '/pedetails/:id', name: 'PEDetails', component: () => import('@/views/PEDetails.vue')},
        {path: '/program-view', name: 'ProgramView', component: () => import('@/views/ProgramView.vue')},
        {path: '/speaker-view', name: 'SpeakerView', component: () => import('@/views/SpeakerView.vue')},
        {path: '/about-summit2024', name: 'AboutSummit', component: () => import('@/views/AboutSummit.vue')},
        {path: '/about-waitro', name: 'AboutWaitro', component: () => import('@/views/AboutWaitro.vue')},
        {path: '/more/destination', name: 'DestinationView', component: () => import('@/views/DestinationView.vue')},
        {path: '/more/venue', name: 'VenueView', component: () => import('@/views/VenueView.vue')},
        {
            path: '/more/accommodation',
            name: 'AccommodationView',
            component: () => import('@/views/AccommodationView.vue')
        },
        {
            path: '/Register-and-login',
            name: 'RegisterAndLogin',
            component: () => import('@/views/RegisterAndLogin.vue')
        },
        // {path: '/select-conference', name: 'SelectConference', component: () => import('@/views/SelectConference.vue')},
        {path: '/hotel-package', name: 'HotelPackage', component: () => import('@/views/HotelPackage.vue')},
        {path: '/payment-view', name: 'PaymentView', component: () => import('@/views/PaymentView.vue')},
        {
            path: '/more/downloadcenter',
            name: 'DownloadCenter',
            component: () => import('@/views/DownloadCenter.vue'),
            //meta: { requiresAuth: true } // 添加这一行来标记需要鉴权
        },
        {path: '/personal-center', name: 'PersonalCenter', component: () => import('@/views/PersonalCenter.vue')},
        {
            path: '/pay-success',
            name: 'PaySuccess',
            component: ()  => import('@/views/PaySuccess.vue'),
            meta: {excludeGlobalComponent: true}
        },
        {
            path: '/pay-error',
            name: 'PayError',
            component: () => import('@/views/PayError.vue'),
            meta: {excludeGlobalComponent: true}
        },
    ],
    scrollBehavior(savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
});

// 在路由实例创建之后，但在导出之前添加此全局前置守卫
/*router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token');

  if (requiresAuth && !token) {
    // 使用 ElMessageBox 自定义提示
    MessageBox.confirm('您当前未登录，是否前往登录页面？', '提示', {
      confirmButtonText: '去登录',
      cancelButtonText: '留在本页',
      type: 'warning',
    })
      .then(() => {
        // 用户点击“去登录”
        next({ name: 'RegisterAndLogin' });
      })
      .catch(() => {
        // 用户点击“留在本页”或者关闭对话框
        next(false);
      });
  } else {
    // 其它情况正常放行
    next();
  }
});*/

// 导航守卫，用于监听页面路由跳转
router.afterEach(() => {
    // 滚动到页面顶部
    window.scrollTo(0, 0);
});
export default router;
