<template>
  <div id="app">
    <el-backtop  v-if="!shouldExcludeGlobalComponents" >
      <div class="backtop">UP</div>
    </el-backtop>
    <MainHeader v-if="!shouldExcludeGlobalComponents" />
    <TopNav v-if="!shouldExcludeGlobalComponents" />
    <router-view />
    <MainBottom v-if="!shouldExcludeGlobalComponents" />
  </div>
</template>

<script>
import MainHeader from '@/components/homeIndex/MainHeader.vue';
import TopNav from '@/components/homeIndex/TopNav.vue';
import MainBottom from '@/components/homeIndex/MainBottom.vue';


export default {
  name: 'App',
  components: { MainHeader, TopNav, MainBottom },

  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    shouldExcludeGlobalComponents() {
      return this.$route.meta.excludeGlobalComponent;
    }
  }
};
</script>
<style lang="scss"></style>