import request from '@/utils/request'
//选择会议
export const getMeetingPackage = (sku, conference, conference_type) => {
  const data = {
    sku: sku,
    conference: conference,
    conference_type: conference_type,
  }
  return request({
    url: '/index/task/api_user_order_insert1',
    method: 'get',
    params: data
  })
}

//选择酒店
export const getHotelPackage = (order_number, hotel) => {
  const data = {
    order_number: order_number,
    hotel: hotel
  }
  return request({
    url: '/index/task/api_user_order_insert2',
    method: 'get',
    params: data
  })
}

//会议列表
export const getMeeting = () => {
  return request({
    url: '/index/task/api_meeting',
    method: 'get',
  })
}
//会议详情
export const getMeetingType = (id) => {
  return request({
    url: '/index/task/api_user_order_insert1_type',
    method: 'get',
    params: { enterprises_id: id }
  })
}
//不选酒店静态页资源
export const getNoHotel = () => {
  return request({
    url: '/index/task/api_no_hotel',
    method: 'get',
  })
}
//删除订单
export const getDeleteOrder = (sku) => {
  const data = {
    sku: sku,
  }
  return request({
    url: '/index/task/api_user_order_delete',
    method: 'get',
    params: data
  })
}
//付款订单
export const getPayOrder = (order_number) => {
  const data = {
    order_number: order_number,
  }
  return request({
    url: '/index/task/api_user_order_insert3_pay',
    method: 'get',
    params: data
  })
}
//接机服务表单提交
export const airportInsert = (data) => {

  return request({
    url: '/index/task/api_airport_insert',
    method: 'get',
    params: data
  })
}

//付款类型选择
export const getPayType = (order_number, order_type, order_code, order_airport) => {
  const data = {
    order_number: order_number,
    order_type: order_type,
    order_code: order_code,
    order_airport: order_airport,
  }
  return request({
    url: '/index/task/api_user_order_insert3',
    method: 'get',
    params: data
  })
}
//协议 
// 1	用户协议
// 2	注册协议
// 3	付款协议
// 4	退款协议
export const getApiProtocol = () => {
  return request({
    url: '/index/task/api_protocol',
    method: 'get',
  })
}
//支付页面静态资源
export const apiPayMent = () => {
  return request({
    url: '/index/task/api_pay_ment',
    method: 'get',
  })
}
// 创建订单 新版
export const createNewOrder = (sku) => {
  return request({
    url: '/index/task/api_user_order_insert_new?sku=' + sku,
    method: 'get',
  })
}
// 获取支付金额
export const getPayMoney = (order_number) => {
  return request({
    url: '/index/task/api_user_order_insert_pay_new?order_number=' + order_number,
    method: 'get',
  })
}
// 获取酒店房型列表
export const getHotelRoomList = () => {
  return request({
    url: '/index/task/api_user_order_hotel',
    method: 'get',
  })
}
// 创建酒店订单
export const createHotelOrder = (hotel_id,order_number, hotel_type_id, hotel_notes, hotel_start_time, hotel_end_time) => {
  return request({
    url: '/index/task/api_user_order_hotel_insert_new',
    method: 'get',
    params: {
      hotel_id: hotel_id,
      order_number: order_number,
      hotel_type_id: hotel_type_id,
      hotel_notes: hotel_notes,
      hotel_start_time: hotel_start_time,
      hotel_end_time: hotel_end_time,
    }
  })
}
