import request from '@/utils/request'
// 演讲者
export const getSpeech = () => {
  return request({
    url: '/index/task/api_index_speech',
    method: 'get',
  })
}
//Grogram
export const getIndexGrogram = () => {
  return request({
    url: '/index/task/api_index_grogram',
    method: 'get',
  })
}
//跳转外链计数
export const getapiNewsNum = () => {
  return request({
    url: '/index/task/api_news_api_access_number',
    method: 'get',
  })
}
//意见反馈左
export const getApiMessageLeft = () => {
  return request({
    url: '/index/task/api_message_left',
    method: 'get',
  })
}

//视频及一下三个模块
export const getApiIndexCenter = () => {
  return request({
    url: '/index/task/api_index_center',
    method: 'get',
  })
}

//底
export const getApiFooter = () => {
  return request({
    url: '/index/task/api_footer',
    method: 'get',
  })
}
//头
export const getApiHead = () => {
  return request({
    url: '/index/task/api_head',
    method: 'get',
  })
}
//公告栏
export const getNoticeDescLimit1 = () => {
  return request({
    url: '/index/noah/notice_desc_limit1',
    method: 'get',
  })
}
//导航栏
export const getParentMenu = () => {
  return request({
    url: '/index/task/api_parent_menu',
    method: 'get',
  })
}
// 意见反馈
export function apiMessage(data) {
  return request({
    url: 'index/task/api_message',
    headers: {
      isToken: false
    },
    method: 'post',
    params: data

  })
}
// 搜索
export function apiFind(content) {
  const data = {
    content: content,
  }
  return request({
    url: '/index/task/api_find',
    headers: {
      isToken: false
    },
    method: 'post',
    params: data

  })
}