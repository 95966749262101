<!-- 头部 -->
<template>
  <div class='container'>
    <div class="content">
      <div class="logo" @click="goToHomePage">
        <img :src="header.src" alt="">
      </div>
      <div class="logo_text">
        <img :src="header.src2" alt="">
      </div>
      <div class="space">
      </div>
      <div class="register">
        <div class="register_btn" @click="toRegisterAndLogin">
          {{ isLoggedIn ? 'My Account' : 'REGISTER NOW' }}
        </div>
        <div class="content_text">
          <div class="date_text">{{ header.content1 }}</div>
          <div class="location_text">{{ header.content2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getApiHead } from '@/api/home';
import { getToken } from '@/utils/auth';
export default {
  name: 'MainHeader',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      header: {},
    };
  },
  //监听属性 类似于data概念
  computed: {
    isLoggedIn() {
      return getToken(); // 假设登录状态存储在全局的user模块中
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getList() {
      getApiHead().then(response => {
        this.header = response.data;
      })
    },
    goToHomePage() {
      // 获取当前路由路径
      const currentPath = this.$route.path;
      // 设置主页面的路由路径
      const homePath = '/home-view';

      // 检查当前路径是否与主页面路径相同
      if (currentPath !== homePath) {
        // 如果不同，则执行导航操作
        this.$router.push(homePath);
      }
    },
    toRegisterAndLogin() {
      const currentPath = this.$route.path;
      const homePath = this.isLoggedIn ? '/personal-center' : '/Register-and-login';
      if (currentPath !== homePath) {
        this.$router.push(homePath);
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类


.container {
  width: 100%;
  height: 200px;
  // padding: 0.5% 0 0 0;
  background-color: #E4ECED;
}

.content {
  display: flex;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  background-color: #E4ECED;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 25%;
  height: 100%;
  cursor: pointer;
}

.logo,
.logo_text {
  margin: 2% auto;
}

.logo img {
  width: 80%;
  height: auto;
}

.logo_text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 25%;
  height: 100%;
}

.logo_text img {
  width: 80%;
  height: auto;
}

.space {
  width: 25%;
  height: 100%;
}

.register {
  width: 25%;
  height: 100%;
  display: flex;
  padding-top: 1%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start
}

.register_btn {
  width: 55%;
  min-width: 215px;
  height: 35%;
  background-color: #05B8B0;
  color: #ffffff;
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  /* 动画效果 */
  animation: slideInFromRight 1s ease-out;
  text-align: center;
  line-height: 65px;
}

.content_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  width: 60%;
  height: 40%;
  color: #000000;
  font-size: 1vw;
  font-weight: 600;
  color: #3F3F3F;
}

.date_text {
  font-weight: 600;
  margin-bottom: 10px;
  /* 间距调整 */
}

.location_text {
  font-weight: normal;
  /* 设置为正常字体 */
}
</style>